import React from 'react';

// map 'MVP Icons' in sketch; 'Modern - Thin Round' in design doc.
// exclude Social Icons
export const account = (
  <path
    fillRule='evenodd'
    d='M5.643 19.241a.782.782 0 0 1-.634-.889c.317-2.142 1.62-4.188 3.525-5.244l.459-.254-.39-.352a4.89 4.89 0 0 1-.797-6.327 4.747 4.747 0 0 1 2.752-2.003 4.894 4.894 0 0 1 6.092 5.72c-.211 1.042-.802 1.97-1.59 2.683l-.308.28.459.253c1.876 1.04 3.185 3.131 3.53 5.26a.765.765 0 0 1-.742.883c-.367.005-.697-.25-.753-.613-.52-3.384-4.067-6.087-7.702-4.324-1.628.79-2.714 2.511-3.014 4.313a.76.76 0 0 1-.887.614zm2.873-10.36a3.36 3.36 0 0 0 3.356 3.355A3.36 3.36 0 0 0 15.23 8.88a3.361 3.361 0 0 0-3.358-3.357A3.36 3.36 0 0 0 8.516 8.88z'
  />
);

export const person = account;

export const magGlass = (
  <path
    fillRule='evenodd'
    d='M19.504 18.461a.76.76 0 0 1 0 1.038.652.652 0 0 1-.956 0L15.2 15.993a6.142 6.142 0 0 1-3.83 1.353C7.858 17.346 5 14.353 5 10.673 5 6.994 7.858 4 11.371 4c3.513 0 6.371 2.994 6.371 6.673a6.82 6.82 0 0 1-1.536 4.333l3.298 3.455zM6.377 10.673c0 2.884 2.24 5.231 4.994 5.231 2.754 0 4.994-2.347 4.994-5.231s-2.24-5.23-4.994-5.23c-2.754 0-4.994 2.346-4.994 5.23z'
  />
);

export const cart1 = (
  <path
    fillRule='evenodd'
    d='M17.39 17.381c-.713 0-1.305.593-1.305 1.31 0 .715.592 1.31 1.305 1.31s1.305-.595 1.305-1.31c0-.717-.592-1.31-1.305-1.31m-9.133 0c-.713 0-1.305.593-1.305 1.31 0 .715.592 1.31 1.305 1.31s1.305-.595 1.305-1.31c0-.717-.592-1.31-1.305-1.31m9.765-2.061c.357 0 .673.376.673.734 0 .359-.295.735-.652.735H7.605a.659.659 0 0 1-.653-.655c0-.219.22-.654.409-1.006.138-.257.179-.553.118-.839L5.77 5.309H4.652A.658.658 0 0 1 4 4.655C4 4.297 4.296 4 4.652 4h1.774c.683 0 .704.819.805 1.309h12.116c.357 0 .653.297.653.655l-1.358 4.917a3.167 3.167 0 0 1-2.509 2.095l-7.356 1.132s.172.257.172.565c0 .308-.305.647-.305.647h9.378zM7.531 6.809l1.139 5.722 7.292-1.02a1.568 1.568 0 0 0 1.253-1.124l1.07-3.679-10.754.101z'
  />
);

export const chevronUp = (
  <path
    fillRule='evenodd'
    d='M4.196 15.803a.774.774 0 0 1 .03-1.061L12.11 7l7.419 7.742a.773.773 0 0 1 0 1.061.699.699 0 0 1-1.016 0L12.079 9.09l-6.867 6.745a.7.7 0 0 1-1.016-.032'
  />
);

export const chevronDown = (
  <path
    fillRule='evenodd'
    d='M19.544 7.236a.773.773 0 0 1-.031 1.06l-7.883 7.743-7.42-7.742a.773.773 0 0 1 0-1.061.699.699 0 0 1 1.017 0l6.433 6.713 6.868-6.745a.698.698 0 0 1 1.016.032'
  />
);

export const chevronLeft = (
  <path
    fillRule='evenodd'
    d='M15.804 19.544a.774.774 0 0 1-1.061-.03L7 11.63l7.742-7.42a.773.773 0 0 1 1.061 0 .697.697 0 0 1 0 1.017L9.09 11.66l6.745 6.867a.698.698 0 0 1-.031 1.016'
  />
);

export const chevronRight = (
  <path
    fillRule='evenodd'
    d='M7.236 4.195a.773.773 0 0 1 1.06.031l7.743 7.883-7.742 7.42a.773.773 0 0 1-1.06 0 .697.697 0 0 1 0-1.017l6.712-6.433-6.745-6.868a.697.697 0 0 1 .032-1.016'
  />
);

export const download = (
  <path
    fillRule='evenodd'
    d='M19.24 19.992H4.75a.75.75 0 0 1 0-1.5h14.49a.75.75 0 1 1 0 1.5zM15.839 9.569a.75.75 0 1 1 1.06 1.061l-4.89 4.89-4.921-4.89a.751.751 0 0 1 1.058-1.064l3.097 3.079V4.75a.75.75 0 1 1 1.5 0v7.914l3.096-3.095z'
  />
);

export const ok = (
  <path
    fillRule='evenodd'
    d='M12 4a8 8 0 0 1 8 8 8 8 0 0 1-8 8 8 8 0 0 1-8-8 8 8 0 0 1 8-8zm0 1.5A6.507 6.507 0 0 0 5.5 12c0 3.584 2.915 6.5 6.5 6.5 3.584 0 6.5-2.916 6.5-6.5S15.584 5.5 12 5.5zm3.316 4.249a.72.72 0 0 1 .195.533.719.719 0 0 1-.252.512l-4.099 3.491a.846.846 0 0 1-.56.209.845.845 0 0 1-.62-.268l-1.298-1.419a.723.723 0 0 1 .056-1.046.774.774 0 0 1 1.067.055l.845.931 3.6-3.053a.771.771 0 0 1 1.066.055z'
  />
);

export const freeship = (
  <path
    fillRule='evenodd'
    d='M20.204 9.973c.024.03.596.766.596 1.627v5.003c0 .75-.611 1.36-1.361 1.36h-.354a2.325 2.325 0 0 1-2.205 1.6 2.309 2.309 0 0 1-2.204-1.6h-.041a1.293 1.293 0 0 1-.613-.162c-.189.106-.4.162-.613.162h-2.647a2.308 2.308 0 0 1-2.203 1.6 2.31 2.31 0 0 1-2.203-1.6h-.997A1.36 1.36 0 0 1 4 16.603V5.31C4 4.588 4.587 4 5.309 4h8.111c.716 0 1.299.597 1.299 1.33v.91h2.48c.701 0 .931.31 1.134.584.039.053.08.109.114.151l.117.127v.058l1.619 2.786.021.027zM13.279 5.44h-7.84v11.083h.917a2.31 2.31 0 0 1 2.203-1.6c1.02 0 1.898.656 2.203 1.6h2.517V5.44zm-4.72 10.923a.871.871 0 0 0-.88.88c0 .494.387.88.88.88a.87.87 0 0 0 .88-.88.871.871 0 0 0-.88-.88zm9.2.88a.87.87 0 0 0-.879-.88.87.87 0 0 0-.88.88c0 .494.385.88.88.88a.87.87 0 0 0 .879-.88zm-.879-2.32c1.027 0 1.901.672 2.205 1.6h.274V12.52h-2.8c-.351 0-.69-.144-.953-.407a1.343 1.343 0 0 1-.407-.953V9.88c0-.75.61-1.36 1.36-1.36h1.018a7.62 7.62 0 0 1-.055-.1c-.117-.214-.322-.592-.439-.74h-2.364v8.721a2.31 2.31 0 0 1 2.161-1.478zm-.241-3.843h2.528a3.401 3.401 0 0 0-.14-.272l-.514-.848h-1.874v1.12z'
  />
);

export const globe = (
  <path
    fillRule='evenodd'
    d='M12 4a8 8 0 0 1 8 8 8 8 0 0 1-8 8 8 8 0 0 1-8-8 8 8 0 0 1 8-8zm-6.5 8c0 3.332 2.521 6.083 5.756 6.454.39-.953.542-1.89.398-2.1-.014.002-.041-.007-.113-.007h-.03c-.847 0-1.349-.383-1.622-.705-.596-.704-.626-1.727-.587-2.298l-3.463-3.4A6.45 6.45 0 0 0 5.5 12zM12 5.5a6.496 6.496 0 0 0-5.477 3.014l1.998 1.962s-.531-1.541-.213-1.897c.235-.264 1.412 0 1.412 0V7.264c0-.556.451-1.006 1.007-1.006h2.083v-.702A6.368 6.368 0 0 0 12 5.5zm.912 10.038c.507.784.249 2.01-.05 2.898a6.47 6.47 0 0 0 3.409-1.549l-.277-1.154h-1.287a.997.997 0 0 1-.997-.997V12.81h-2.815l-.069.385c-.056.334-.08 1.14.209 1.479.069.081.214.197.487.173.599.03 1.099.241 1.39.691zM18.5 12c0-2.77-1.744-5.134-4.19-6.068v.829a.997.997 0 0 1-.997.997H11.22s.411 1.91 0 2.321c-.218.218-1.235 0-1.235 0l.139 1.231h4.085c.553 0 1.001.448 1.001 1.002v1.921h1.179c.462 0 .863.317.971.766l.118.486A6.451 6.451 0 0 0 18.5 12z'
  />
);

export const video = (
  <path
    fillRule='evenodd'
    d='M19.996 4C20.55 4 21 4.45 21 5.005v13.99C21 19.55 20.55 20 19.996 20H4.004C3.45 20 3 19.55 3 18.995V5.005C3 4.45 3.45 4 4.004 4h15.992zm-2.268 14.5H19.5v-2.055h-1.772V18.5zM4.5 18.5h1.827v-2.055H4.5V18.5zm.001-11.085h1.827V5.5H4.501v1.915zm13.227 0H19.5V5.5h-1.772v1.915zm0 7.53H19.5V12.68h-1.772v2.265zm-9.9 3.555h8.4v-5.82h-8.4v5.82zM4.5 14.945h1.827V12.68H4.5v2.265zm.001-3.765h1.827V8.915H4.501v2.265zm11.727-2.265V5.5h-8.4v5.68h8.4V8.915zm1.5 2.265H19.5V8.915h-1.772v2.265z'
  />
);

export const hamburger = (
  <path
    fillRule='evenodd'
    d='M19.248 7.5H4.752A.751.751 0 0 1 4 6.75c0-.414.337-.75.752-.75h14.496a.75.75 0 1 1 0 1.5m0 5.423H4.752a.75.75 0 0 1 0-1.5h14.496a.75.75 0 1 1 0 1.5m0 5.423H4.752a.75.75 0 1 1 0-1.5h14.496a.75.75 0 1 1 0 1.5'
  />
);

export const buttonLeft = (
  <path
    fillRule='evenodd'
    d='M12 4a8 8 0 0 1 8 8 8 8 0 0 1-8 8 8 8 0 0 1-8-8 8 8 0 0 1 8-8zm0 1.5A6.508 6.508 0 0 0 5.5 12c0 3.584 2.916 6.5 6.5 6.5s6.5-2.916 6.5-6.5-2.916-6.5-6.5-6.5zm.42 10.052l-3.206-3.55 3.22-3.567a.75.75 0 1 1 1.108 1.01l-2.297 2.557 2.282 2.539a.75.75 0 1 1-1.107 1.01z'
  />
);

export const buttonRight = (
  <path
    fillRule='evenodd'
    d='M12 20a8 8 0 0 1-8-8 8 8 0 0 1 8-8 8 8 0 0 1 8 8 8 8 0 0 1-8 8zm0-1.5c3.584 0 6.5-2.916 6.5-6.5S15.584 5.5 12 5.5A6.508 6.508 0 0 0 5.5 12c0 3.584 2.916 6.5 6.5 6.5zm-.42-10.052l3.206 3.55-3.22 3.567a.75.75 0 1 1-1.108-1.01l2.297-2.557-2.282-2.539a.75.75 0 1 1 1.107-1.01z'
  />
);

export const close = (
  <path
    fillRule='evenodd'
    d='M19.219 5.22a.75.75 0 0 0-1.061 0l-5.939 5.939-5.939-5.94a.75.75 0 1 0-1.061 1.062l5.939 5.939-5.939 5.939a.752.752 0 0 0 0 1.06.752.752 0 0 0 1.061 0l5.939-5.938 5.939 5.939a.75.75 0 1 0 1.061-1.061l-5.939-5.94 5.939-5.938a.75.75 0 0 0 0-1.061'
  />
);

export const location = (
  <path
    fillRule='evenodd'
    d='M12.306 19.735a1.046 1.046 0 0 1-1.392 0C9.206 18.195 5 14.009 5 10.162 5 6.764 7.977 4 11.636 4c3.66 0 6.642 2.764 6.636 6.162-.006 3.754-4.264 8.019-5.966 9.573zm-.34-14.32c-.97-.058-1.913.246-2.742.718-6.113 3.478-.302 9.75 2.035 11.933.2.187.52.183.714-.008 1.577-1.544 4.786-5.039 4.786-7.896 0-2.52-2.122-4.588-4.792-4.747zm-.33 7.361c-1.695 0-3.073-1.28-3.073-2.853 0-1.574 1.378-2.853 3.073-2.853 1.694 0 3.073 1.28 3.073 2.853 0 1.573-1.379 2.853-3.073 2.853zm0-4.3c-.86 0-1.56.648-1.56 1.447 0 .798.7 1.448 1.56 1.448.86 0 1.56-.65 1.56-1.448 0-.799-.7-1.448-1.56-1.448z'
  />
);

export const okCalendar = (
  <path
    fillRule='evenodd'
    d='M15.796 4c.415 0 .75.335.75.75v.328h1.965c.55 0 .995.445.995.995v12.675a.995.995 0 0 1-.995.995H5.002a.997.997 0 0 1-.996-.995v-8.84H4v-1.5h.006V6.073c0-.55.447-.995.996-.995h1.965v-.239a.75.75 0 1 1 1.5 0v.239h6.579V4.75a.75.75 0 0 1 .75-.75zM5.506 18.243h12.5V9.908h-12.5v8.335zm10.29-10.851a.75.75 0 0 1-.75-.75v-.064H8.467v.153a.75.75 0 1 1-1.5 0v-.153H5.506v1.83h12.5v-1.83h-1.46v.064c0 .415-.335.75-.75.75zm-1.158 4.832a.72.72 0 0 1 .195.533.719.719 0 0 1-.252.512l-3.236 2.627a.843.843 0 0 1-.559.21.845.845 0 0 1-.62-.269l-1.298-1.419a.723.723 0 0 1 .056-1.046.774.774 0 0 1 1.067.055l.845.931 2.736-2.189a.772.772 0 0 1 1.066.055z'
  />
);

export const questionBubble = (
  <path
    fillRule='evenodd'
    d='M6.351 19.367a.751.751 0 0 1-.68-1.067l.953-2.044C4.948 14.917 4 13.052 4 11.055 4 7.165 7.589 4 12 4s8 3.165 8 7.055c0 3.889-3.589 7.054-8 7.054-.531 0-1.08-.056-1.67-.171-.466.224-1.465.615-3.737 1.389a.753.753 0 0 1-.242.04zM12 5.5c-3.584 0-6.5 2.492-6.5 5.555 0 1.689.905 3.271 2.484 4.341a.75.75 0 0 1 .259.937l-.461.988c.944-.339 1.746-.644 1.964-.761a.742.742 0 0 1 .628-.145c.59.13 1.122.194 1.626.194 3.584 0 6.5-2.492 6.5-5.554C18.5 7.992 15.584 5.5 12 5.5zm-.79 8.267c0-.42.335-.756.757-.756.42 0 .77.324.77.756a.77.77 0 0 1-.77.771.756.756 0 0 1-.756-.77zm1.935-4.566c0-.684-.484-.993-1.194-.993-.828 0-1.177.443-1.177 1.235H9.668c0-1.446.895-2.279 2.242-2.279 1.244 0 2.329.71 2.329 2.058 0 1.881-1.748 1.681-1.748 2.401v.735h-1.064v-.745c0-1.698 1.718-1.194 1.718-2.412z'
  />
);

export const digitalProduct = (
  <path
    fillRule='evenodd'
    d='M13.743 16.33a.596.596 0 0 1 .883 0 .701.701 0 0 1 0 .946L12.08 20l-2.425-2.568a.703.703 0 0 1-.003-.945.596.596 0 0 1 .883-.003l.907.953v-5.548c0-.37.279-.67.625-.67.345 0 .624.3.624.67v5.566l1.05-1.124zm1.818-2.361c-.016-.355.229-.657.56-.692.775-.083 2.379-.065 2.597-2.016.109-.967-.058-1.729-.496-2.264-.761-.931-2.13-.962-2.143-.962l-.439-.006-.145-.444c-.03-.088-.788-2.248-3.426-2.248-3.496 0-3.919 3.686-3.935 3.843l-.072.686-.638-.096c-.006 0-1-.134-1.622.45-.366.344-.553.903-.553 1.66 0 .492.142.866.433 1.141.551.521 1.534.636 2.211.633.315-.002.578.248.617.583.046.399-.244.749-.62.75-.816.002-2.16-.137-3.035-.964-.56-.529-.855-1.27-.855-2.143 0-1.166.333-2.066.99-2.675.646-.6 1.454-.754 1.998-.78C7.373 6.621 8.765 4 12.069 4c2.796 0 4.019 1.874 4.432 2.733.627.083 1.829.372 2.654 1.374.685.833.957 1.947.804 3.313-.275 2.457-2.316 2.982-3.705 3.184-.358.052-.677-.248-.693-.635z'
  />
);

export const money = (
  <path
    fillRule='evenodd'
    d='M18.981 7C19.544 7 20 7.456 20 8.019v7.458c0 .562-.456 1.018-1.019 1.018H5.019A1.019 1.019 0 0 1 4 15.477V8.019C4 7.456 4.456 7 5.019 7h13.962zm-.481 7.995V8.5h-5.032c1.066.602 1.804 1.823 1.804 3.248 0 1.424-.738 2.645-1.804 3.247H18.5zM5.5 8.5v6.495h5.032c-1.066-.602-1.804-1.823-1.804-3.247 0-1.425.738-2.646 1.804-3.248H5.5zm6.5 5.4c.978 0 1.772-.966 1.772-2.152 0-1.187-.794-2.152-1.772-2.152s-1.772.965-1.772 2.152c0 1.186.794 2.152 1.772 2.152zm-4.154-2.152a.765.765 0 1 1-1.53 0 .765.765 0 0 1 1.53 0zm8.262 0a.765.765 0 1 1 1.53 0 .765.765 0 0 1-1.53 0z'
  />
);

export const plus = (
  <path
    fillRule='evenodd'
    d='M11.899 4a.75.75 0 0 0-.75.75v6.4H4.75a.75.75 0 1 0 0 1.499h6.399v6.4a.75.75 0 0 0 1.5 0v-6.4h6.399a.749.749 0 1 0 0-1.499h-6.399v-6.4a.75.75 0 0 0-.75-.75'
  />
);

export const minus = (
  <path
    fill='currentColor'
    fillRule='nonzero'
    d='M5 13.25h14a.75.75 0 1 0 0-1.5H5a.75.75 0 1 0 0 1.5z'
  />
);

export const clock = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M12 8.428a.888.888 0 00-.888.888v3.696l3.208 1.944a.888.888 0 00.926-1.516l-2.358-1.452V9.316A.888.888 0 0012 8.428zm8 3.56C20 16.414 16.418 20 12.001 20c-4.12 0-7.512-3.117-7.953-7.126-.053-.487.35-.886.84-.886.492 0 .883.401.951.888a6.227 6.227 0 005.2 5.288 6.221 6.221 0 006.892-4.271 6.235 6.235 0 00-3.17-7.47 6.214 6.214 0 00-7.854 2.005h1.536a.89.89 0 110 1.78H4V5.756a.89.89 0 011.779 0v1.22a7.991 7.991 0 018.877-2.521A8.01 8.01 0 0120 11.988z'
    fill='currentColor'
  />
);

export const alert = (
  <g fillRule='evenodd' fill='currentColor'>
    <path d='M12 7.94751C12.4694 7.94751 12.85 8.32807 12.85 8.79751V12.2975C12.85 12.767 12.4694 13.1475 12 13.1475C11.5306 13.1475 11.15 12.767 11.15 12.2975V8.79751C11.15 8.32807 11.5306 7.94751 12 7.94751ZM12.875 14.7038C12.875 15.187 12.4832 15.5788 12 15.5788C11.5168 15.5788 11.125 15.187 11.125 14.7038C11.125 14.2205 11.5168 13.8288 12 13.8288C12.4832 13.8288 12.875 14.2205 12.875 14.7038Z' />
    <path d='M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 18.5C15.5899 18.5 18.5 15.5899 18.5 12C18.5 8.41015 15.5899 5.5 12 5.5C8.41015 5.5 5.5 8.41015 5.5 12C5.5 15.5899 8.41015 18.5 12 18.5Z' />
  </g>
);

export const pause = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    fill='currentColor'
    d='M9.75 18.25C9.75 18.6642 9.41421 19 9 19V19C8.58579 19 8.25 18.6642 8.25 18.25V5.75C8.25 5.33579 8.58579 5 9 5V5C9.41421 5 9.75 5.33579 9.75 5.75V18.25ZM15.75 5.75C15.75 5.33579 15.4142 5 15 5V5C14.5858 5 14.25 5.33579 14.25 5.75V18.25C14.25 18.6642 14.5858 19 15 19V19C15.4142 19 15.75 18.6642 15.75 18.25V5.75Z'
  />
);
